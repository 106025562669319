import Layout from "@/layout/Container.vue"

export const commonRouter = [
  {
    path: '/',
    redirect: '/goods/list'
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: "登录",
    },
    component: () => import("@/views/login")
  },
  {
    path: '/home',
    name: 'Home',
    meta: {
      title: "首页",
    },
    component: Layout,
    children: [
      {
        path: '/home/index',
        name: 'HomeIndex',
        meta: {
          title: "数据总览",
        },
        component: () => import("@/views/home/index.vue")
      }
    ],
  },
  {
    path: '/warning',
    name: 'Warning',
    meta: {
      title: "预警通知",
    },
    component: Layout,
    children: [
      {
        path: '/warning/buy',
        name: 'WarningBuy',
        meta: {
          title: "采购预警",
        },
        component: () => import("@/views/warning/buy/index.vue")
      },
      {
        path: '/warning/sell',
        name: 'WarningSell',
        meta: {
          title: "售价预警",
        },
        component: () => import("@/views/warning/sell/index.vue")
      },
    ],
  },

  {
    path: '/goods',
    name: 'Goods',
    meta: {
      title: '商品管理'
    },
    component: Layout,
    children: [
      {
        path: '/goods/list',
        name: 'GoodsList',
        meta: {
          title: '商品列表'
        },
        component: () => import("@/views/goods/list/index.vue")
      },
      {
        path: '/goods/special',
        name: 'GoodsSpecial',
        meta: {
          title: '特价商品'
        },
        component: () => import("@/views/goods/special/index.vue")
      },
      {
        path: '/goods/orderCate',
        name: 'GoodsOrderCate',
        meta: {
          title: '下单分类'
        },
        component: () => import("@/views/goods/orderCate/index.vue")
      },
      {
        path: '/goods/norm',
        name: 'GoodsNorm',
        meta: {
          title: '商品标名'
        },
        component: () => import("@/views/goods/norm/index.vue")
      },
      // {
      //   path: '/goods/indexCopy',
      //   name: 'GoodsIndexCopy',
      //   meta: {
      //     title: '商品列表2'
      //   },
      //   component: () => import("@/views/goods/list/indexCopy.vue")
      // },
      {
        path: '/goods/type',
        name: 'GoodsType',
        meta: {
          title: '商品分类'
        },
        component: () => import("@/views/goods/type/index.vue")
      },
      // {
      //   path: '/goods/unit',
      //   name: 'GoodsUnit',
      //   meta: {
      //     title: '计件单位'
      //   },
      //   component: () => import("@/views/goods/unit/index.vue")
      // }
    ]
  },
  {
    path: '/order',
    name: 'Order',
    meta: {
      title: '订单管理'
    },
    component: Layout,
    children: [
      {
        path: '/order/list',
        name: 'OrderList',
        meta: {
          title: '订单列表'
        },
        component: () => import("@/views/order/list/index.vue")
      },
      {
        path: '/order/templet',
        name: 'OrderTemplet',
        meta: {
          title: '发货单模版'
        },
        component: () => import("@/views/order/templet/index.vue")
      }
    ]
  },
  {
    path: '/purchase',
    name: 'Purchase',
    meta: {
      title: '采购管理'
    },
    component: Layout,
    children: [
      {
        path: '/purchase/verify',
        name: 'PurchaseVerify',
        meta: {
          title: '采购审核',
        },
        component: () => import("@/views/purchase/verify/index.vue")
      },
      {
        path: '/purchase/list',
        name: 'PurchaseList',
        meta: {
          title: '采购订单'
        },
        component: () => import("@/views/purchase/list/index.vue")
      },
      {
        path: '/purchase/gather',
        name: 'PurchaseGather',
        meta: {
          title: '采购汇总',
          requiresAuthAll: true, // 为true时(区域管理员和员工端都不展示该菜单)
        },
        component: () => import("@/views/purchase/gather/index.vue")
      },
      {
        path: '/purchase/buyer',
        name: 'PurchaseBuyer',
        meta: {
          title: '采购员',
          requiresAffairs: true, // 为true时(财务账号不展示该菜单)
          requiresAuthAll: true, // 为true时(区域管理员和员工端都不展示该菜单)
        },
        component: () => import("@/views/purchase/buyer/index.vue")
      },
      {
        path: '/purchase/receive',
        name: 'Receive',
        meta: {
          title: '单条收货',
          isShow: false, // 不显示在菜单里
        },
        component: () => import("@/views/purchase/list/components/Untitled2.vue")
      },
      {
        path: '/purchase/batchReceive',
        name: 'BatchReceive',
        meta: {
          title: '批量收货',
          isShow: false, // 不显示在菜单里
        },
        component: () => import("@/views/purchase/list/batchReceive.vue")
      },
      {
        path: '/purchase/freight',
        name: 'Freight',
        meta: {
          title: '配送运费',
        },
        component: () => import("@/views/purchase/freight/index.vue")
      },
      // {
      //   path: '/purchase/order',
      //   name: 'PurchaseOrder',
      //   meta: {
      //     title: '供应商订单',
      //   },
      //   component: () => import("@/views/purchase/order/index.vue")
      // },
      // {
      //   path: '/purchase/fruitage',
      //   name: 'PurchaseFruitage',
      //   meta: {
      //     title: '果蔬采购',
      //   },
      //   component: () => import("@/views/purchase/fruitage/index.vue")
      // },


      // {
      //   path: '/purchase/spoilage',
      //   name: 'PurchaseSpoilage',
      //   meta: {
      //     title: '损耗列表'
      //   },
      //   component: () => import("@/views/purchase/spoilage/index.vue")
      // },
    ]
  },
  {
    path: '/supplychain',
    name: 'Supplychain',
    meta: {
      title: '供应链管理',
      requiresAuthAll: true, // 为true时(区域管理员和员工端都不展示该菜单)
    },
    component: Layout,
    children: [
      {
        path: '/supplychain/supplier',
        name: 'SupplychainSupplier',
        meta: {
          title: '供应商',
          requiresAffairs: true, // 为true时(财务账号不展示该菜单)
        },
        component: () => import("@/views/supplychain/supplier/index.vue")
      },
      {
        path: '/supplychain/sole',
        name: 'SupplychainSole',
        meta: {
          title: '一品多商',
        },
        component: () => import("@/views/supplychain/sole/index.vue")
      },
      {
        path: '/supplychain/report',
        name: 'SupplychainReport',
        meta: {
          title: '质检报告',
        },
        component: () => import("@/views/supplychain/report/index.vue")
      },
      {
        path: '/supplychain/purchaseprice',
        name: 'SupplychainPurchaseprice',
        meta: {
          title: '商品采购价',
        },
        component: () => import("@/views/supplychain/purchaseprice/index.vue")
      },
      {
        path: '/supplychain/score',
        name: 'SupplychainScore',
        meta: {
          title: '供应商评选',
        },
        component: () => import("@/views/supplychain/score/index.vue")
      },
      {
        path: '/supplychain/mockup',
        name: 'SupplychainMockup',
        meta: {
          title: '遴选大模型',
        },
        component: () => import("@/views/supplychain/mockup/index.vue")
      },
      {
        path: '/supplychain/tagprinter',
        name: 'SupplychainTagprinter',
        meta: {
          title: '标签打印机',
        },
        component: () => import("@/views/supplychain/tagprinter/index.vue")
      },
      {
        path: '/supplychain/enquiry',
        name: 'SupplychainEnquiry',
        meta: {
          title: '采购询价',
        },
        component: () => import("@/views/supplychain/enquiry/index.vue")
      },
    ]
  },
  {
    path: '/sorting',
    name: 'Sorting',
    meta: {
      title: '分拣管理',
      requiresAffairs: true, // 为true时(财务账号不展示该菜单)
    },
    component: Layout,
    children: [
      {
        path: '/sorting/commodity',
        name: 'SortingCommodity',
        meta: {
          title: '商品分拣'
        },
        component: () => import("@/views/sorting/commodity/index.vue")
      },
      {
        path: '/sorting/progress',
        name: 'SortingProgress',
        meta: {
          title: '分拣进度'
        },
        component: () => import("@/views/sorting/progress/index.vue")
      },
      {
        path: '/sorting/sorter',
        name: 'SortingSorter',
        meta: {
          title: '分拣员'
        },
        component: () => import("@/views/sorting/sorter/index.vue")
      },
      // {
      //   path: '/sorting/app',
      //   name: 'SortingApp',
      //   meta: {
      //     title: '版本管理'
      //   },
      //   component: () => import("@/views/sorting/app/index.vue")
      // }
    ]
  },
  {
    path: '/send',
    name: 'Send',
    meta: {
      title: '配送管理',
      requiresAffairs: true, // 为true时(财务账号不展示该菜单)
    },
    component: Layout,
    children: [
      // {
      //   path: '/send/list',
      //   name: 'SendList',
      //   meta: {
      //     title: '配送列表'
      //   },
      //   component: () => import("@/views/send/list/index.vue")
      // },
      {
        path: '/send/service',
        name: 'SendService',
        meta: {
          title: '退货售后'
        },
        component: () => import("@/views/send/service/index.vue")
      },
      {
        path: '/send/itinerary',
        name: 'SendItinerary',
        meta: {
          title: '配送员'
        },
        component: () => import("@/views/send/itinerary/index.vue")
      },
      {
        path: '/send/cars',
        name: 'SendCars',
        meta: {
          title: '车辆管理'
        },
        component: () => import("@/views/send/cars/index.vue")
      },
      // {
      //   path: '/send/templet',
      //   name: 'SendTemplet',
      //   meta: {
      //     title: '发货单模板'
      //   },
      //   component: () => import("@/views/send/templet/index.vue")
      // },

    ]
  },
  {
    path: '/store',
    name: 'Store',
    meta: {
      title: '仓库管理',
      // requiresAuthAll: true, // 为true时(区域管理员和员工端都不展示该菜单)
      requiresAuth: true, // 为true时(员工端不展示该菜单)
    },
    component: Layout,
    children: [
      {
        path: '/store/list',
        name: 'StoreList',
        meta: {
          title: '仓库设置',
          requiresAffairs: true, // 为true时(财务账号不展示该菜单)
        },
        component: () => import("@/views/store/list/index.vue")
      },
      {
        path: '/store/set',
        name: 'StoreSet',
        meta: {
          title: '入库列表'
        },
        component: () => import("@/views/store/set/index.vue")
      },
      {
        path: '/store/stock',
        name: 'StoreStock',
        meta: {
          title: '现有库存'
        },
        component: () => import("@/views/store/stock/index.vue")
      },
      {
        path: '/store/get',
        name: 'StoreGet',
        meta: {
          title: '出库列表'
        },
        component: () => import("@/views/store/get/index.vue")
      },

      {
        path: '/store/check',
        name: 'StoreCheck',
        meta: {
          title: '库存盘点'
        },
        component: () => import("@/views/store/check/index.vue")
      },

    ]
  },
  {
    path: '/user',
    name: 'User',
    meta: {
      title: '用户管理',
      requiresAffairs: true, // 为true时(财务账号不展示该菜单)
    },
    component: Layout,
    children: [
      {
        path: '/user/merchant',
        name: 'UserMerchant',
        meta: {
          title: '客户列表'
        },
        component: () => import("@/views/user/merchant/index.vue")
      },
      {
        path: '/user/verify',
        name: 'UserVerify',
        meta: {
          title: '审核列表'
        },
        component: () => import("@/views/user/verify/index.vue")
      },
      // {
      //   path: '/user/list',
      //   name: 'UserList',
      //   meta: {
      //     title: '用户列表'
      //   },
      //   component: () => import("@/views/user/list/index.vue")
      // },
      {
        path: '/user/group',
        name: 'UserGroup',
        meta: {
          title: '客户分组'
        },
        component: () => import("@/views/user/group/index.vue")
      },
      // {
      //   path: '/user/feedback',
      //   name: 'UserFeedback',
      //   meta: {
      //     title: '意见反馈'
      //   },
      //   component: () => import("@/views/user/feedback/index.vue")
      // },
    ]
  },
  {
    path: '/finance',
    name: 'Finance',
    meta: {
      title: '财务管理',
      requiresAuth: true, // 为true时(员工端不展示该菜单)
    },
    component: Layout,
    children: [
      {
        path: '/finance/goodsCount',
        name: 'financeGoodsCount',
        meta: {
          title: '商品毛利统计'
        },
        component: () => import("@/views/finance/goodsCount/index.vue")
      },
      {
        path: '/finance/orderCount',
        name: 'financeOrderCount',
        meta: {
          title: '订单毛利统计'
        },
        component: () => import("@/views/finance/orderCount/index.vue")
      },
      {
        path: '/order/settle',
        name: 'OrderSettle',
        meta: {
          title: '订单结算'
        },
        component: () => import("@/views/order/settle/index.vue")
      },
      {
        path: '/finance/bill',
        name: 'FinanceBill',
        meta: {
          title: '供应商账单'
        },
        component: () => import("@/views/finance/bill/index.vue")
      },
      {
        path: '/client/bill',
        name: 'ClientBill',
        meta: {
          title: '销售报表'
        },
        component: () => import("@/views/finance/clienBill/index.vue")
      },
      {
        path: '/purchase/bill',
        name: 'PurchaseBill',
        meta: {
          title: '采购报表'
        },
        component: () => import("@/views/finance/purchaseBill/index.vue")
      },
      {
        path: '/finance/invoice',
        name: 'FinanceInvoice',
        meta: {
          title: '开票申请'
        },
        component: () => import("@/views/finance/invoice/index.vue")
      },
    ]
  },
  {
    path: '/banner',
    name: 'Banner',
    meta: {
      title: '广告管理',
      requiresAuthAll: true, // 为true时(区域管理员和员工端都不展示该菜单)
    },
    component: Layout,
    children: [
      {
        path: '/banner/index',
        name: 'BannerIndex',
        meta: {
          title: '轮播图',
        },
        component: () => import("@/views/banner/index.vue")
      },
    ]
  },
  {
    path: '/account',
    name: 'Account',
    meta: {
      title: '账号管理',
      requiresAuth: true, // 为true时(员工端不展示该菜单)
      requiresAffairs: true, // 为true时(财务账号不展示该菜单)
      requiresAuthAll: true, // 为true时(区域管理员和员工端都不展示该菜单)
    },
    component: Layout,
    children: [
      {
        path: '/account/list',
        name: 'AccountList',
        meta: {
          title: '账号列表'
        },
        component: () => import("@/views/account/index.vue")
      },
      {
        path: '/role/list',
        name: 'RoletList',
        meta: {
          title: '角色权限'
        },
        component: () => import("@/views/role/index.vue")
      },
    ]
  },
  {
    path: '/log',
    name: 'Log',
    meta: {
      title: '操作日志',
      requiresAuth: true, // 为true时(员工端不展示该菜单)
      requiresAffairs: true, // 为true时(财务账号不展示该菜单)
      requiresAuthAll: true, // 为true时(区域管理员和员工端都不展示该菜单)
    },
    component: Layout,
    children: [
      {
        path: '/log/list',
        name: 'LogList',
        meta: {
          title: '操作日志'
        },
        component: () => import("@/views/log/index.vue")
      },
    ]
  },
  {
    path: '/novice',
    name: 'Novice',
    meta: {
      title: '帮助指导',
      isShow: false
    },
    component: Layout,
    children: [
      {
        path: '/novice/index',
        name: 'NoviceIndex',
        meta: {
          title: '帮助指导',
        },
        component: () => import("@/views/novice/index.vue")
      }
    ]
  },

  {
    path: '*',
    name: '404',
    meta: {
      title: '404',
    },
    component: () => import("@/views/404")
  }
]



