<template>
  <el-aside width="auto">
    <el-menu
      class="menu__wrap el-menu-vertical-demo"
      router
      :default-active="$route.path"
      :collapse="isCollapse"
      :unique-opened="false"
      active-text-color="#2cb167"
    >
      <!-- 渲染路由菜单时判断本次登录账号是否有仓库，若无仓库隐藏仓库管理菜单 -->
      <el-submenu v-for="item in routesArr" :key="item.name" :index="item.path" 
      :class="stashArr.length == 0 && item.path == '/store'
            ? 'display'
            : ''
        ">
        <template>
          <template slot="title">
            <div :class="'icon-' + item.path.substr(1)"></div>
            <span>{{ item.meta.title }}</span>
            <el-badge is-dot class="item" v-if="item.path == '/warning' && isRead > 0" style="margin-left:5px;">
            </el-badge>
          </template>
          <el-menu-item-group>
            <template v-for="child in item.children">
              <el-menu-item :key="child.name" :index="child.path"
                >{{ child.meta.title }}
                <el-badge is-dot class="item" v-if="item.path == '/warning' && isRead > 0" style="margin-left:5px;">
                </el-badge>
              </el-menu-item>
            </template>
          </el-menu-item-group>
        </template>
      </el-submenu>
    </el-menu>
  </el-aside>
</template>
<script>
import { getStorage } from "@/storage";
import { format } from 'echarts';
export default {
  name: "LayoutNaviBar",
  props: ["isCollapse"],
  data() {
    return {
      routes: [],
      roleType: getStorage("role_type"), // 1:超级管理员，2:管理员, 3：员工, 4：财务
      open_pick: getStorage("open_pick") || 1, // 是否开启分拣功能：1-开启；2-关闭
      access_list: getStorage('access_list'), // 权限列表
      pathArr: [],
      routesArr: [],
      stashArr: [], // 仓库 
      isRead: 0
    };
  },
  watch: {
    $route(to, from) {
      if(to.path == '/warning/buy'|| to.path == '/warning/sell') {
        this.setRead()
      }
      this.getRead();
    }
  },
  mounted() {
    this.getRead();
    this.getStash();
    this.pathArr = this.access_list.map(item => '/'+item.web_path);
    this.routes = this.$router.options.routes.slice(
      2,
      this.$router.options.routes.length - 1
    );
    this.routesArr = filterTree(this.routes,this.pathArr);

    function filterTree(tree, names) {
      return tree
      .map(node => ({ ...node })) // 浅拷贝节点
      .filter(function filterNode(node) {
        // 检查当前节点的name是否在names数组中
        const isMatch = names.includes(node.path);

        // 如果有子节点，递归过滤子节点
        if (node.children) {
          node.children = filterTree(node.children, names);
        }

        // 如果当前节点匹配或者其子节点中有匹配的，保留该节点
        return isMatch || node.children && node.children.length > 0;
      });
    }
  },
  methods: {
    // 获取该登录账号的仓库
    getStash() {
      this.$http.get('/admin/stash/list', { params:{ page: 1, count: 10000}}).then(res => {
        if(res.code == 1) {
          this.stashArr = res.data.list;
        }
      })
    },
    getRead() {
      this.$http.get('/admin/warn/getRead', { params:{}}).then(res => {
        if(res.code == 1) {
          this.isRead = res.data.count;
        }
      })
    },
    setRead() {
      this.$http.get('/admin/warn/read', { params:{}}).then(res => {
        if(res.code == 1) {}
      })
    }
    
  }
};
</script>
<style scoped lang="scss">
.menu__wrap {
  height: 100%;
  box-sizing: border-box;
  &::v-deep {
    .el-menu-item-group__title {
      display: none;
    }
  }
  .menu-icon {
    margin-right: 10px;
  }
}
.el-menu {
  border: none;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
  border-right: solid 1px #d3f3e1;
}
.el-menu-item.is-active {
  background: #eaf7f0;
}
.display {
  display: none !important;
}
</style>

<style>
.el-submenu.is-active > .el-submenu__title {
  color: #2cb167 !important;
}
.el-submenu.is-active > .el-submenu__icon-arrow {
  color: #2cb167 !important;
}
.icon-home {
  width: 20px;
  height: 20px;
  background-image: url("~@/assets/img/nav/i1.png");
  display: inline-block;
  margin: 0 10px 0 0;
}
.is-active .icon-home {
  background-image: url("~@/assets/img/nav/i1-.png");
}
.icon-goods {
  width: 20px;
  height: 20px;
  background-image: url("~@/assets/img/nav/i2.png");
  display: inline-block;
  margin: 0 10px 0 0;
}
.is-active .icon-goods {
  background-image: url("~@/assets/img/nav/i2-.png");
}
.icon-order {
  width: 20px;
  height: 20px;
  background-image: url("~@/assets/img/nav/i3.png");
  display: inline-block;
  margin: 0 10px 0 0;
}
.is-active .icon-order {
  background-image: url("~@/assets/img/nav/i3-.png");
}
.icon-store {
  width: 20px;
  height: 20px;
  background-image: url("~@/assets/img/nav/i4.png");
  display: inline-block;
  margin: 0 10px 0 0;
}
.is-active .icon-store {
  background-image: url("~@/assets/img/nav/i4-.png");
}
.icon-send {
  width: 20px;
  height: 20px;
  background-image: url("~@/assets/img/nav/i5.png");
  display: inline-block;
  margin: 0 10px 0 0;
}
.is-active .icon-send {
  background-image: url("~@/assets/img/nav/i5-.png");
}
.icon-purchase {
  width: 20px;
  height: 20px;
  background-image: url("~@/assets/img/nav/i5.png");
  display: inline-block;
  margin: 0 10px 0 0;
}
.is-active .icon-purchase {
  background-image: url("~@/assets/img/nav/i5-.png");
}
.icon-supplychain {
  width: 20px;
  height: 20px;
  background-image: url("~@/assets/img/nav/i5.png");
  display: inline-block;
  margin: 0 10px 0 0;
}
.is-active .icon-supplychain {
  background-image: url("~@/assets/img/nav/i5-.png");
}
.icon-user {
  width: 20px;
  height: 20px;
  background-image: url("~@/assets/img/nav/i6.png");
  display: inline-block;
  margin: 0 10px 0 0;
}
.is-active .icon-user {
  background-image: url("~@/assets/img/nav/i6-.png");
}
.icon-account {
  width: 20px;
  height: 20px;
  background-image: url("~@/assets/img/nav/i7.png");
  display: inline-block;
  margin: 0 10px 0 0;
}
.is-active .icon-account {
  background-image: url("~@/assets/img/nav/i7-.png");
}
.icon-sorting {
  width: 20px;
  height: 20px;
  background-image: url("~@/assets/img/nav/i7.png");
  display: inline-block;
  margin: 0 10px 0 0;
}
.is-active .icon-sorting {
  background-image: url("~@/assets/img/nav/i7-.png");
}
.icon-finance {
  width: 20px;
  height: 20px;
  background-image: url("~@/assets/img/nav/i3.png");
  display: inline-block;
  margin: 0 10px 0 0;
}
.is-active .icon-finance {
  background-image: url("~@/assets/img/nav/i3-.png");
}
.icon-banner {
  width: 20px;
  height: 20px;
  background-image: url("~@/assets/img/nav/i3.png");
  display: inline-block;
  margin: 0 10px 0 0;
}
.is-active .icon-banner {
  background-image: url("~@/assets/img/nav/i3-.png");
}
.icon-novice {
  width: 20px;
  height: 20px;
  background-image: url("~@/assets/img/nav/i3.png");
  display: inline-block;
  margin: 0 10px 0 0;
}
.is-active .icon-novice {
  background-image: url("~@/assets/img/nav/i3-.png");
}
.icon-log {
  width: 20px;
  height: 20px;
  background-image: url("~@/assets/img/nav/i3.png");
  display: inline-block;
  margin: 0 10px 0 0;
}
.is-active .icon-log {
  background-image: url("~@/assets/img/nav/i3-.png");
}
.icon-warning {
  width: 20px;
  height: 20px;
  background-image: url("~@/assets/img/nav/i3.png");
  display: inline-block;
  margin: 0 10px 0 0;
}
.is-active .icon-warning {
  background-image: url("~@/assets/img/nav/i3-.png");
}
</style>
