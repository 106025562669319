<template>
  <el-container class="layout__wrap">
    <el-header class="header__wrap shadow" height="60px">
      <div class="logo__wrap">
        <div class="logoBg">
          <img class="logo" src="@/assets/img/logo2.png" alt="">
        </div>
        <!-- <span class="logo_txt">{{companyName}}</span> -->
        <div class="J_menutoggle" @click="toggleColl">
          <el-tooltip
            effect="dark"
            :content="isCollapse ? `展开` : `收起`"
            placement="bottom"
          >
            <i class="el-icon-menu" color="#fff"></i>
          </el-tooltip>
        </div>
        <div style="margin-left:15px;color:#fff;">{{subject_name}}</div>
      </div>
      <div class="avatar__wrap">
        <el-button round size="mini" icon="el-icon-s-help" style="margin-right:10px;color: #2cb167;" @click="handleHelp">帮助指导</el-button>
        <div class="title" style="display:flex;align-items: center;">
          <img style="width:24px;margin-right:10px;" src="@/assets/img/default@2x.png" alt="">
          <div> {{userName}}</div>
        </div>
        <el-popover
          placement="bottom"
          width="400"
          trigger="click"
          :append-to-body='false'>
          <template v-if="total > 0">
            <div class="is_rade" v-if="no_read_count > 0" @click="handleMessAll">全部已读</div>
            <div class="messageWrap" >
              <div v-for="(item,index) in messageList" :key="index" class="list" @click="handleMessage(item)">
                <div style="display: flex;align-items: center;">
                  <span :class="item.is_read == 0 ? 'dot' : 'dot2'">&nbsp;</span>
                  <span class="name">{{item.title}}</span>
                </div>
                <div class="time">{{item.create_time}}</div>
              </div>
            </div>
          </template>
          <template v-else>
            <el-empty :image-size="100" description="暂无通知"></el-empty>
          </template>
          <el-badge slot="reference" :value="no_read_count" :hidden="no_read_count == 0 ? true : false" :max="99" class="item" style="margin-left:40px;margin-right:16px;">
            <img style="width:24px;" src="@/assets/img/notion@2x.png" alt="">
          </el-badge>
        </el-popover>
        <el-tooltip class="item" effect="dark" content="退出登录" placement="bottom">
          <div class="logout" @click="logout" >
            <img style="width:24px;" src="@/assets/img/exit@2x.png" alt="">
          </div>
        </el-tooltip>
      </div>
    </el-header>
    <el-container style="overflow-y:scroll;">
      <NaviBar :isCollapse="isCollapse"></NaviBar>
      <el-main class="layout__main">
        <page-header></page-header>
        <router-view></router-view>
      </el-main>
    </el-container>

    <!-- 查看系统通知 -->
    <div class="messDialog">
      <el-dialog
        :title="currTitle"
        :visible.sync="dialogVisibleMessage"
        width="600px"
        :before-close="handleClose">
        <div v-html="currContent" style="min-height:100px;"></div>
        <!-- <div style="text-align: right;font-size: 14px;margin-top:20px;">{{currTime}}</div> -->
      </el-dialog>
    </div>

    <!-- 系统通知未读弹窗群，最多10个 -->
    <template v-if="noMessageList.length > 0 ">
      <el-dialog
        v-for="(dialog, index) in noMessageList"
        top="2vh"
        :key="index"
        title=""
        :visible.sync="dialog.visible"
        :close-on-click-modal="false"
        :show-close="false"
        width="600px"
        center
        :class="'dialog'+index">
        <div style="text-align: center;font-size: 16px;font-weight: bold;margin-bottom:20px;">{{dialog.title}}</div>
        <div v-html="dialog.content" class="dialog-content"></div>
        <div style="text-align: right;font-size: 14px;margin-top:20px;">{{dialog.create_time}}</div>
        <span slot="footer" class="dialog-footer">
          <el-button type="warining" plain @click="handleCloseAll">全部关闭</el-button>
          <el-button type="primary" @click="handleCloseOnce(dialog,index)">知道了</el-button>
        </span>
      </el-dialog>
    </template>

  </el-container>
</template>
<script>
import PageHeader from './PageHeader.vue';
import NaviBar from './NaviBar';
import { setStorage, getStorage, clear } from "@/storage"
export default {
  name: 'LayoutContainer',
  components: {
    NaviBar,
    PageHeader
  },
  data() {
    return {
      isCollapse: false,
      toggleMenu: false,
      userName: getStorage('userName'),
      companyName: getStorage('companyName'),
      subject_name: getStorage('subject_name'),
      messageList: [],
      dialogVisibleMessage: false,
      no_read_count: 0, // 新消息数量
      total: 0, // 消息总数
      currTitle: '',
      currContent: '',
      currTime: '',
      dialogVisible: true,
      noMessageList: [],
      is_showDialog: getStorage('is_showDialog'), 
      time: null,
    }
  },
  created() {
    this.getMessage();
    this.$store.commit('set_is_sys', getStorage('is_sys'))
  },
  mounted() {
    this.getGoodsType();
    this.getMerchant();
    this.getArea();

    this.$nextTick(() => {
      // 10分钟刷一次未读的新通知接口
      this.time = setInterval(() => {
        this.getNoMessage();
      },600000)
    })
  },
  beforeDestroy () {
    this.time = null;
  },
  methods: {
    logout() {
      this.$confirm("请确认是否退出登录？", "提示", {
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            clear(); // 删除token
            window.location.reload();
            done()
          } else {
            done()
          }
        }
      })
    },
    toggleColl() {
      this.isCollapse = !this.isCollapse;
      this.toggleMenu = !this.toggleMenu;
    },
    handleClose() {
      this.dialogVisibleMessage = false;
      this.is_showDialog = false;
      this.getMessage();
    },
    // 获取10分钟之内的(未读}通知列表
    getNoMessage() {
      this.$http.get('/admin/message/list',{params: {page:1,count:100,is_time:1,is_read:1}}).then(res => {
        if(res.code == 1) {
          this.no_read_count = res.data.no_read_count;
          let arr = res.data.list;
          this.noMessageList = arr.map(v => {
            return {
              ...v,
              visible: true,
            }
          }).slice(0,10).reverse(); // 最多取前10项
          console.log(this.noMessageList,"******")
        }
      })
    },
    // 获取通知列表
    getMessage() {
      this.$http.get('/admin/message/list',{params: {page:1,count:10000}}).then(res => {
        if(res.code == 1) {
          this.messageList = res.data.list;
          this.total = res.data.total;
          this.no_read_count = res.data.no_read_count;
          let arr = res.data.list.filter(item => item.is_read == 0)
          if(this.is_showDialog == true) {
            this.noMessageList = arr.map(v => {
              return {
                ...v,
                visible: true,
              }
            }).slice(0,10).reverse(); // 最多取前10项
          } else {
            this.noMessageList = []
          }
        }
      })
    },
    // 查看通知
    handleMessage(row) {
      this.dialogVisibleMessage = true;
      this.$http.get('/admin/message/info',{params: {id:row.id}}).then(res => {
        if(res.code == 1) {
          this.currTitle = res.data.title;
          this.currContent = res.data.content;
          this.currTime = res.data.create_time;
        }
      })
    },
    // 单条已读
    handleCloseOnce(row,index) {
      this.$http.get('/admin/message/info',{params: {id:row.id}}).then(res => {
        if(res.code == 1) {
          this.noMessageList[index].visible = false;
          this.no_read_count--
          // this.getMessage()
        }
      })
    },
    // 全部已读
    handleMessAll() {
      this.$confirm('请确认全部已读操作？','提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$http.post('/admin/message/all',{}).then(res => {
          if(res.code == 1) {
            this.dialogVisibleMessage = false;
            this.$message.success("操作成功！")
            this.getMessage();
          }
        })
      }).catch(() => {})
    },
    // 全部关闭
    handleCloseAll() {
      setStorage('is_showDialog', false);
      let arr = this.noMessageList.map(item => ({ ...item, visible: false }));
      this.noMessageList = arr
      console.log(this.noMessageList,"arr")
      // 刷新通知列表
      this.$http.get('/admin/message/list',{params: {page:1,count:10000}}).then(res => {
        if(res.code == 1) {
          this.messageList = res.data.list;
          this.total = res.data.total;
          this.no_read_count = res.data.no_read_count;
          this.noMessageList = [];
        }
      })
    },
    // 获取所有商品分类
    getGoodsType() {
      this.$http.post('/admin/cate/all',{}).then(res => {
        if(res.code == 1) {
          this.$store.commit('setGoodsType', res.data)
        }
      })
    },
    // 获取客户
    getMerchant() {
      this.$http.post('/admin/admin/merchantList',{page:1,count: 1000}).then(res => {
        if(res.code == 1) {
          this.$store.commit('setMerchant', res.data.list)
        }
      })
    },
    // 获取全国省市
    getArea() {
      this.$http.post('/common/district/all', {is_all:1}).then(res => {
        if(res.code === 1) {
          this.areaArr = res.data.list;
          for (const key in this.areaArr) {
            // 删除第三层children属性
            for (const i in this.areaArr[key].children) {
              delete this.areaArr[key].children[i].children
            }
          }
          this.$store.commit('setArea', this.areaArr)
        }
      })
    },
    handleHelp() {
      window.open(this.$router.resolve({name: 'NoviceIndex'}).href, '_blank');
      // window.open(this.$router.resolve({name: 'GoodsType'}).href, '_blank');
    }
    
  }
}
</script>

<style scoped lang="scss">
.layout__wrap {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  
  .header__wrap {
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $theme !important;
    border-bottom: solid 1px #eee;
    // background-image: linear-gradient(to right, $theme, #0bCFFF);
    .logo__wrap {
      display: flex;
      align-items: center;
      .logo {
        display: block;
        width:180px;
        // height: 32px;
      }
      .logo_txt {
        color: #5a5a5a;
        font-weight: bold;
        margin-left: 40px;
      }
      .J_menutoggle {
        color: #fff;
        margin-left: 20px;
      }
    }
    .avatar__wrap {
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 14px;
      cursor: pointer;

      .title {
        width: 100%;
        text-align: center;
      }
      .logout {
        // width: 130px;
        margin-right: 40px;
        margin-left: 15px;
      }
      
      ::v-deep .el-popover {
        min-width: 80px;
      }
    }
  }
  .layout__main {
    display: flex;
    flex-direction: column;
    padding: 0;
    background-color: #EEF6FD;
    position: relative;
    overflow: hidden;

    .page-header__wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #FFFFFF;
      color: #595959;
      border-bottom: solid 1px #F1F1F1;

      .title {
        height: 58px;
        line-height: 58px;
        
      }
    }

    &>div {
      flex: 1;
      min-height: 0;
      // overflow-y: scroll;
    }

  }
}
.logoBg {
  padding: 0 20px;
  // background: rgb(255, 255, 255);
  width: 210px;
  height: 60px;
  display: flex;
  align-items: center;
  border-bottom: solid 1px #eee;
}
.is_rade {
  color:#2cb167;
  cursor: pointer;
  padding:12px;
  border-bottom:solid 1px #eee;
  box-shadow: 0 0 8px 0 rgba(232, 237, 250, 0.6), 0 2px 4px 0 rgba(232, 237, 250, 0.5);
}
.messageWrap {
  max-height: 600px;
  overflow-y: scroll;
}
.messageWrap::-webkit-scrollbar {
  width:3px;
}
.messageWrap::-webkit-scrollbar-thumb {
  background: #8d8d8d;
  border-radius: 5px;
}
.messageWrap .list {
  padding: 10px;
  border-top: solid 1px #eee;
  cursor: pointer;
}
.messageWrap .list:first-child {
  border: none;
}
.messageWrap .list:hover .name {
  cursor: pointer;
  color: #2cb167;
  text-decoration: underline;
}
.messageWrap .list:hover .time {
  cursor: pointer;
  color: #2cb167;
}
.messageWrap .name {
  font-size: 15px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 360px;
}
.messageWrap .time {
  color: #666666;
  margin-top: 10px;
  margin-left: 20px;
}
.messageWrap .dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #f00808;
  display: inline-block;
  margin-right: 10px;
}
.messageWrap .dot2 {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #ccc;
  display: inline-block;
  margin-right: 10px;
}
.messDialog ::v-deep .el-dialog__header{
  background: #F5F5F5;
}
.messDialog ::v-deep .el-dialog__title {
  line-height: 24px;
  font-size: 18px;
  color: #303133;
  width: 520px;
  display: block;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.avatar__wrap ::v-deep .el-popover {
  padding: 0;
}
.content {
  min-height: 300px;
}
.dialog-content {
  // min-height:100px;
  height:400px;
  overflow-y:scroll;
}
.dialog-content::-webkit-scrollbar {
  width:3px;
}
.dialog-content::-webkit-scrollbar-thumb {
  background: #8d8d8d;
  border-radius: 5px;
}
.messItem {
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
}
.dialog1 {
  margin-top: 10px;
  margin-left: 20px;
}
.dialog2 {
  margin-top: 20px;
  margin-left: 30px;
}
.dialog3 {
  margin-top: 30px;
  margin-left: 40px;
}
.dialog4 {
  margin-top: 40px;
  margin-left: 50px;
}
.dialog5 {
  margin-top: 50px;
  margin-left: 60px;
}
.dialog6 {
  margin-top: 60px;
  margin-left: 70px;
}
.dialog7 {
  margin-top: 70px;
  margin-left: 80px;
}
.dialog8 {
  margin-top:80px;
  margin-left: 90px;
}
.dialog9 {
  margin-top:90px;
  margin-left: 100px;
}
.dialog10 {
  margin-top:10px;
  margin-left: 110px;
}
</style>
